<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :validatePermission="false"
    @save="onSave('Reminders')"
    @cancel="onCancel('Reminders')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'ReminderCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Reminders',
      breadcrumbs: [
        {
          text: 'Напоминания',
          route: { name: 'Reminders' },
        },
        {
          text: 'Создание напоминания',
        },
      ],
      pageHeader: 'Создание напоминания',
      initialForm: {
        accountId: null,
        whenRemind: null,
        comment: null,
        relatedObjectType: null,
      },
      form: {},
    };
  },

  computed: {
    ...mapState({
      reminderRelatedObjectTypes: (state) =>
        state.common.reminderRelatedObjectTypes,
      employeeAccounts: (state) => state.common.employeeAccounts,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'accountId',
              label: 'Аккаунт',
              list: this.employeeAccounts.data,
            },
            {
              type: 'fullDateTime',
              key: 'whenRemind',
              label: 'Дата напоминания',
              attributes: {
                format: 'DD.MM.YYYY HH:mm',
                valueType: 'DD.MM.YYYY HH:mm:ss',
                timeTitleFormat: 'DD.MM.YYYY HH:mm',
                disabledDate: this.dateDisabled,
                disabledTime: this.timeToDisabled,
              },
            },
            {
              type: 'textarea',
              key: 'comment',
              label: 'Комментарий',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.form.accountId = +localStorage.getItem('accountId');
    this.isLoadingPage = true;

    const employeeAccounts = this.getCommonList({
      name: 'EmployeeAccounts',
    });

    const reminderRelatedObjectTypes = this.getCommonList({
      name: 'ReminderRelatedObjectTypes',
    });

    Promise.all([employeeAccounts, reminderRelatedObjectTypes]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      createItem: 'editableList/createItem',
      getListforCommon: 'editableList/getListforCommon',
    }),

    dateDisabled(date) {
      const currentDate = new Date();

      return date < new Date(currentDate.setHours(0, 0, 0, 0));
    },

    timeToDisabled(date) {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      return date < new Date(currentDate.setHours(hours, minutes + 10, 0, 0));
    },
  },
};
</script>
